<template>
  <div class="row">
    <purchase-request-header-status :current="current" :purchase_request="full_purchase_request"></purchase-request-header-status>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="delivery_date" class="col-form-label col-form-label-sm">Delivery On Before <span class="custom-required">*</span></label>
                <datepicker readonly disabled v-model="purchase_request.delivery_date" v-validate="'required'" id="delivery_date" name="delivery_date" :format="customDeliveryDate" :disabled-dates="disabledDates" placeholder="Delivery Date" ></datepicker>
                <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('delivery_date')">Delivery date required</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <form action="">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th :class="{change_color: scrollPosition > 150}">Name</th>
                  <th :class="{change_color: scrollPosition > 150}">Name</th>
                  <th :class="{change_color: scrollPosition > 150}">W. Stock Qty</th>
                  <th :class="{change_color: scrollPosition > 150}">Requested Qty</th>
                  <th :class="{change_color: scrollPosition > 150}">Unit Price</th>
                  <th :class="{change_color: scrollPosition > 150}">Sub Total</th>
                  <th :class="{change_color: scrollPosition > 150}">Total</th>
                  <th :class="{change_color: scrollPosition > 150}">Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in products" :key="index">
                  <td>{{ product.name }}</td>
                  <td>{{ product.unit }}</td>
                  <td>{{ product.available_quantity }}</td>
                  <td>
                    <input class="form-control form-control-sm" v-model="product.quantity" :id="'qty_'+product.id" v-validate="{ max: 6, regex: /^[0-9]*$/ }"  :name="'quantity' + index">
                  </td>
                  <td>
                    <input class="form-control form-control-sm" v-model="product.unit_price" :id="'unitPrice_'+product.id" v-validate="{ max: 6, regex: /^[0-9]*(?:\.[0-9]{0,2})?$/ }" :name="'unit_price' + index">
                  </td>
                  <td>
                    <input class="form-control form-control-sm" v-model="subTotalRow[index]" :id="'sub_total_'+product.id" v-validate="{ max: 7, regex: /^[0-9]*$/  }" :name="'sub_total' + index" readonly disabled >
                  </td>
                  <td>
                    <input class="form-control form-control-sm" v-model="totalRow[index]" :id="'total_'+product.id" v-validate="{ max: 7, regex: /^[0-9]*$/  }" :name="'total' + index" readonly disabled >
                  </td>
                  <td>
                    <input class="form-control form-control-sm" v-model="product.remarks" :id="'remarks_'+product.id" :name="'remarks' + index">
                  </td>
                </tr>
                <!--  Total, Others, shipping-->
                <tr>
                  <th style="text-align: right" colspan="5">SUBTOTAL</th>
                  <td>
                    <input class="form-control form-control-sm" v-model="subtotal" v-validate="'required'" name="grandSubtotal"  id="grandSubtotal" readonly>
                  </td>
                </tr>
                <tr>
                  <th style="text-align: right" colspan="5">OTHER</th>
                  <td>
                    <input class="form-control form-control-sm" v-model="purchase_request.others" v-validate="{ required: true, max: 6, regex: /^[0-9]*(?:\.[0-9]{0,2})?$/  }" name="other"  id="other" value="0">
                  </td>
                </tr>
                <tr>
                  <th style="text-align: right" colspan="5">TOTAL</th>
                  <td>
                    <input class="form-control form-control-sm" v-model="grandTotal" v-validate="'required'" name="grandTotal"  id="grandTotal" readonly>
                  </td>
                </tr>
              </tbody>
            </table>
            <a-skeleton active :loading="loading"></a-skeleton>
            <a-button class="btn btn-success waves-effect waves-light mr-2" :loading="updatePR" @click.prevent="update('ADMIN_UPDATE')"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Update Purchase Request</a-button>
            <a-button class="btn btn-success waves-effect waves-light mr-2" :loading="updateAPR" @click.prevent="update('ADMIN_APPROVED')"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Update Purchase Request And Approved</a-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import apiClient from '@/services/axios'
import $ from 'jquery'
import purchaseRequestHeaderStatus from '@/views/purchaseRequest/partial/purchase_request_header_status'

export default {
  name: 'create',
  components: { Datepicker, purchaseRequestHeaderStatus },
  data() {
    return {
      vendors: [],
      products: [],
      productIds: [],
      full_purchase_request: {},
      purchase_request: {
        delivery_date: '',
        vat: 0,
        others: 0,
      },
      purchase_request_items: [],
      validation_errors: {},
      updatePR: false,
      updateAPR: false,
      loader: false,
      flag: false,
      loading: false,
      disabledDates: {
        to: new Date(Date.now()),
      },
      current: 0,
      scrollPosition: null,
    }
  },
  mounted() {
    this.getCode()
    window.addEventListener('scroll', this.updateScroll)
  },
  computed: {
    subTotalRow() {
      return this.products.map((product) => {
        return (parseFloat(product.quantity || 0) * parseFloat(product.unit_price || 0) || 0)
      })
    },
    totalRow() {
      return this.products.map((product) => {
        var subTotal = (parseFloat(product.quantity || 0) * parseFloat(product.unit_price || 0)) || 0
        return (subTotal) || 0
      })
    },
    subtotal() {
      let total = 0
      const totalRow = this.totalRow || []
      total = totalRow.reduce((subTotal, total) => total + subTotal || 0, 0)
      return parseFloat(total).toFixed(2) || 0
    },
    grandTotal() {
      let total = 0
      const subtotal = this.subtotal || 0
      const others = this.purchase_request.others
      total = parseFloat(subtotal) + parseFloat(others)
      return parseFloat(total).toFixed(2) || 0
    },
  },
  methods: {
    getCode() {
      this.loading = true
      const purchaseRequestId = this.$route.params.purchase_request_id
      apiClient.get('api/admin/purchase-requests/' + purchaseRequestId + '/edit')
        .then(response => {
          this.loading = false
          const data = response.data
          this.vendors = data.vendors
          this.products = data.products
          this.productIds = data.productIds
          this.purchase_request = data.purchase_request
          this.full_purchase_request = data.purchase_request
          this.current = data.max_item_purchase_request_status
        })
        .catch(error => {
          console.log(error)
        })
    },
    customDeliveryDate(date) {
      this.purchase_request.delivery_date = moment(date).format('YYYY-MM-DD')
      return moment(date).format('YYYY-MM-DD')
    },
    update(type) {
      this.$validator.validate().then(valid => {
        if (valid) {
          if (type === 'ADMIN_UPDATE') { this.updatePR = true }
          if (type === 'ADMIN_APPROVED') { this.updateAPR = true }
          const orderArray = []
          this.productIds.forEach(id => {
            const qtyDiv = '#qty_' + id
            const quantity = $(qtyDiv).val()

            const unitPriceDiv = '#unitPrice_' + id
            const unitPrice = $(unitPriceDiv).val()

            const subTotalDiv = '#sub_total_' + id
            const subTotal = $(subTotalDiv).val()

            const totalDiv = '#total_' + id
            const total = $(totalDiv).val()

            const remarksDiv = '#remarks_' + id
            const remarks = $(remarksDiv).val()

            if (quantity && unitPrice) {
              const order = {
                product_id: id,
                quantity: quantity,
                unit_price: unitPrice,
                sub_total: subTotal,
                total: total,
                remarks: remarks,
              }
              orderArray.push(order)
            }
          })

          if (orderArray.length === 0) {
            this.$notification.error({
              message: 'Insert at least 1 item',
            })
            return
          }
          const formData = new FormData()
          formData.append('purchase_request_items', JSON.stringify(orderArray))
          formData.append('delivery_date', this.purchase_request.delivery_date)
          formData.append('subtotal', this.subtotal)
          formData.append('others', this.purchase_request.others)
          formData.append('grand_total', this.grandTotal)
          if (type === 'ADMIN_APPROVED') {
            formData.append('purchase_request_status', 'ADMIN_APPROVED')
          }
          apiClient.post('api/admin/purchase-request/update/' + this.purchase_request.id, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              this.updatePR = false
              this.updateAPR = false
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                // this.$router.push({ name: 'adminPurchaseRequest' })
                this.$router.push({ name: 'purchaseRequestDetail', params: { purchase_request_id: response.data.purchase_request_id } })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            }).catch(error => {
              this.updatePR = false
              this.updateAPR = false
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
  },
}
</script>

<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
.change_color {
  background-color: #4B7CF3;
  color: #ffffff;
}
</style>
